import * as React from "react";
import { Helmet } from "react-helmet";
import ImageMeta from "../components/seo-image-meta";

const WebsiteMeta = ({
  canonical,
  meta_title,
  meta_description,
  title,
  description,
  image,
  type,
  og_title,
  og_description,
  twitter_title,
  twitter_description,
}) => {
  const jsonLd = {
    "@context": `https://schema.org/`,
    "@type": type,
    url: canonical,
    headline: meta_title || title,
    image: image
      ? {
          "@type": `ImageObject`,
          url: image,
        }
      : undefined,
    description: meta_description || description,
  };

  return (
    <>
      <Helmet>
        <title>{meta_title || title}</title>
        <meta name="description" content={meta_description || description} />
        <link rel="canonical" href={canonical} />

        <meta property="og:title" content={og_title || meta_title || title} />
        <meta
          property="og:description"
          content={og_description || meta_description || description}
        />
        <meta property="og:url" content={canonical} />

        <meta
          name="twitter:title"
          content={twitter_title || meta_title || title}
        />
        <meta
          name="twitter:description"
          content={twitter_description || meta_description || description}
        />
        <meta name="twitter:url" content={canonical} />

        <script type="application/ld+json">
          {JSON.stringify(jsonLd, undefined, 4)}
        </script>
      </Helmet>
      <ImageMeta image={image} />
    </>
  );
};

export default WebsiteMeta;
