import * as React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import BlogCard, { BlogCardGrid } from "../components/blog-card";
import PageWrapper from "../components/page-wrapper";
import WebsiteMeta from "../components/seo-website-meta";
import PageLayout from "../layouts/page-layout";

const Wrapper = styled(PageWrapper)`
  .subtitle {
    color: var(--theme-color-text-dim);
  }
`;

const GhostTag = ({ data }) => {
  if (!data.allGhostPage) return null;

  return (
    <PageLayout>
      <WebsiteMeta
        image={data.ghostTag.feature_image}
        title={data.ghostTag.name}
        description={data.ghostTag.description}
        canonical={data.ghostTag.canonical_url}
        meta_title={data.ghostTag.meta_title}
        meta_description={data.ghostTag.meta_description}
        og_title={data.ghostTag.og_title}
        og_description={data.ghostTag.og_description}
        twitter_title={data.ghostTag.twitter_title}
        twitter_description={data.ghostTag.twitter_description}
        type="Series"
      />
      <Wrapper>
        <StyledSectionPageWrapper>
          <h2>{data.ghostTag.name}</h2>
          <p className="subtitle">{data.ghostTag.description}</p>
          {data.ghostTag.localImage && (
            <GatsbyImage
              image={getImage(data.ghostTag.localImage)}
              className="feature_image"
              alt={data.ghostTag.name}
            />
          )}
          <StyledBlogCardGrid>
            {data.allGhostPage.nodes.map((post) => (
              <BlogCard
                post={post}
                key={post.slug}
                prefix={`/${data.ghostTag.slug}`}
              />
            ))}
          </StyledBlogCardGrid>
        </StyledSectionPageWrapper>
      </Wrapper>
    </PageLayout>
  );
};
const StyledSectionPageWrapper = styled(PageWrapper)`
  padding: 32px 0 48px;

  .feature-image {
    width: 100%;
    object-fit: cover;
  }
`;
const StyledBlogCardGrid = styled(BlogCardGrid)`
  margin-top: 3.428571em;
`;

export const query = graphql`
  query ($slug: String!) {
    allGhostPage(filter: { tags: { elemMatch: { slug: { eq: $slug } } } }) {
      nodes {
        slug
        image: localImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        excerpt
        publishDate: published_at(fromNow: true)
        tags {
          name
        }
      }
    }
    ghostTag(slug: { eq: $slug }) {
      name
      slug
      description
      localImage {
        childImageSharp {
          gatsbyImageData
        }
      }
      canonical_url
      meta_title
      meta_description
      og_title
      og_description
      twitter_title
      twitter_description
    }
  }
`;

export default GhostTag;
